import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import {
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import "../../../scss/main.css";
import typographyStyles from "../../../theme/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { getDataHandlerWithToken } from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import backBtn from "../../../assets/backBtn.svg";

const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addEditInfo: {
    ...typographyStyles.h6,
    fontWeight: "300",
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },
};

const ViewConsult = () => {
  const [consult, setConsult] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchConsult = async () => {
    try {
      const res = await getDataHandlerWithToken("viewConsult", {
        consultId: id,
      });
      if (res.responseCode === 200) {
        setConsult(res.result);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const renderHTMLContent = (htmlContent) => {
    return { __html: htmlContent };
  };
  useEffect(() => {
    fetchConsult();
  }, []);

  if (!consult) {
    return <div>Loading...</div>;
  }

  return (
    <DashboardLayout sectionName="Consult Management" title="Consult Details">
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>View Consult</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <Box className="add-edit-content-section">
          <Box className="add-edit-content">
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Company Name:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.companyName || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Company Logo:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <Avatar src={consult?.companyLogo} alt="Company Logo" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Consult Name:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.consultName || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Consult Logo:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <Avatar src={consult?.consultLogo} alt="Consult Logo" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Description:
                    </TableCell>
                    <TableCell
                      sx={addEditStyle.addEditInfo}
                      dangerouslySetInnerHTML={renderHTMLContent(
                        consult?.consultDescription || "--"
                      )}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Website URL:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      <a
                        href={consult?.websiteURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {consult?.websiteURL || "--"}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Email:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.email || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Country:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.country || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Country Code:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.countryCode || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>Phone:</TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.phone || "--"}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Services:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.services?.join(", ") || "--"}
                    </TableCell>
                  </TableRow>



                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Specializations:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.specialisations?.join(", ") || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Availability:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.availability || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Status:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.status || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Address:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.address || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Latitude:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.location?.coordinates[0] || "--"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={addEditStyle.addEditLabel}>
                      Longitude:
                    </TableCell>
                    <TableCell sx={addEditStyle.addEditInfo}>
                      {consult?.location?.coordinates[1] || "--"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              sx={{ ...addEditStyle.addEditTitle, marginTop: "20px" }}
            >
              Plans
            </Typography>
            <List>
              {consult?.plans?.length === 0 ? (
                <ListItem>
                  <ListItemText primary="No plans available" />
                </ListItem>
              ) : (
                consult?.plans?.map((plan) => (
                  <ListItem key={plan._id} alignItems="flex-start">
                    <ListItemText
                      primary={plan?.serviceName}
                      secondary={`Price: $${plan.price}`}
                    />
                  </ListItem>
                ))
              )}
            </List>
            <Typography
              sx={{ ...addEditStyle.addEditTitle, marginTop: "20px" }}
            >
              Packages
            </Typography>
            <List>
              {consult?.packages?.length === 0 ? (
                <ListItem>
                  <ListItemText primary="No packages available" />
                </ListItem>
              ) : (
                consult?.packages?.map((packageItem) => (
                  <ListItem key={packageItem?._id} alignItems="flex-start">
                    <ListItemText
                      primary={packageItem?.packageName}
                      secondary={`Price: $${packageItem?.price}`}
                    />
                  </ListItem>
                ))
              )}
            </List>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ViewConsult;
