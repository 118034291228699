import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Tooltip,
} from "@mui/material";
import "../scss/main.css";

const PaginationTableStyle = {
  rowStyle: {
    backgroundColor: "rgb(225,140,168)",
    color: "white",
  },
};

const PaginationTable = forwardRef(
  ({ columns, data, rowsPerPageOptions }, ref) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    console.log(page);
    const handleChangeRowsPerPage = (event) => {
      const newValue = parseInt(event.target.value, 10);
      console.log("Selected Rows Per Page:", newValue);
      setRowsPerPage(newValue);
      setPage(0);
    };
    

    

    return (
      <Paper>
        <TableContainer ref={ref}>
          <Table>
            <TableHead>
              <TableRow sx={PaginationTableStyle.rowStyle}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      textAlign: "left",
                      color: "white",
                      fontSize: 14,
                      fontWeight: 700,
                      fontFamily: "Poppins",
                      padding: "4px",
                      paddingLeft: "20px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column) => {
                      const cellValue = row[column.id];
                      const displayValue =
                        cellValue && cellValue.length > 25
                          ? `${cellValue.slice(0, 25)}...`
                          : cellValue;

                      return (
                        <TableCell
                          key={column.id}
                          sx={{
                            textAlign: "left",
                            // fontFamily: "Poppins",
                            padding: "4px",
                            paddingLeft: "20px",
                            height: "49px",
                            fontSize: "0.875rem",
                          }}
                        >
                          {cellValue && cellValue.length > 25 ? (
                            <Tooltip title={cellValue} arrow>
                              <span>{displayValue}</span>
                            </Tooltip>
                          ) : (
                            displayValue
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {(
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectIcon": {
                color: "rgb(225,140,168)",
              },
            }}
          />
        )}
      </Paper>
    );
  }
);

PaginationTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

PaginationTable.defaultProps = {
  rowsPerPageOptions: [10, 25],
};

export default PaginationTable;
