import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Divider,
} from "@mui/material";
import {
  Dashboard,
  Category,
  AccountCircle,
  ExitToApp,
  ExpandLess,
  ExpandMore,
  FitnessCenter,
  Book,
  LocalDining,
  Fastfood,
  Restaurant,
  SportsHandball,
  EventNote,
  Subscriptions,
  TrackChanges,
  KeyboardOptionKey,
  MenuBook,
} from "@mui/icons-material";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ForumIcon from "@mui/icons-material/Forum";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "../scss/main.css";
import profileImg from "../assets/profileimg-h2m.png";
import { toast } from "react-hot-toast";
import { getDataHandlerWithToken } from "../apiconfig/service";
import "../scss/main.css";
import typography from "../theme/Typography";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import PregnantWomanIcon from "@mui/icons-material/PregnantWoman";
import MedicationIcon from "@mui/icons-material/Medication";
import MenuBookIcon from "@mui/icons-material/MenuBook";

const sidebarStyle = {
  sidebarAdminName: {
    "& .MuiTypography-root": {
      fontWeight: "600",
      fontSize: "20px",
      // fontFamily: "Poppins",
    },
  },
  activeSidbarList: {
    cursor: "pointer",
    backgroundColor: "rgb(225, 140, 168)",
    borderRadius: "15px",

    "& .MuiTypography-root": {
      color: "rgb(255, 254, 255)",
      // fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "14px",
    },
    "& .MuiSvgIcon-root": {
      color: "rgb(0, 156, 203) !important",
    },
  },
  sidebarItem: {
    cursor: "pointer",

    "& .MuiSvgIcon-root": {
      color: "rgb(225, 140, 168) !important",
    },

    "& .MuiTypography-root": {
      // fontWeight: "500",
      fontSize: "14px",
      // fontFamily: "sans-serif",
    },
    "& .MuiSvgIcon-root": {
      color: "rgb(225, 140, 168) !important",
    },
  },
};

const Sidebar = ({ onLogoutClick }) => {
  const [openCategory, setOpenCategory] = useState(false);

  const [openMenu, setOpenMenu] = useState(null);

  const sidebarRef = useRef(null);
  const location = useLocation();

  const toggleMenu = (menuName) => {
    const newOpenMenu = openMenu === menuName ? null : menuName;
    setOpenMenu(newOpenMenu);
    localStorage.setItem("openMenu", newOpenMenu);
  };
  const closeAllCollapses = () => {
    setOpenMenu(null);
    localStorage.setItem("openMenu", null);
  };
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     // Clear menu collapse state when the window is closed or refreshed
  //     localStorage.removeItem("openMenu");
  //     localStorage.removeItem("openStagewise");
  //     localStorage.removeItem("openRecipe");
  //     localStorage.removeItem("openConsult");
  //   };

  //   // Listen for the beforeunload event
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Cleanup the event listener on unmount
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const navigate = useNavigate();

  const [user, setUser] = useState(
    () => JSON.parse(sessionStorage.getItem("user")) || {}
  );

  const fullName = user.fullName;

  const isActive = (path) => {
    const firstSegment = location.pathname.split("/")[1];
    // console.log(location.pathname.split('/')[1]);
    return firstSegment === path;
  };
  const saveScrollPosition = () => {
    if (sidebarRef.current) {
      const scrollPosition = sidebarRef.current.scrollTop;
      localStorage.setItem("sidebarScrollPosition", scrollPosition);
    }
  };

  useEffect(() => {
    // Use the updated user from session storage on component mount
    const updatedUser = JSON.parse(sessionStorage.getItem("user"));
    if (updatedUser) {
      setUser(updatedUser);
    }
  }, []);

  const displayName = (user.fullName || "admin").slice(0, 12);
  const profilePicture = user.profilePicture;

  useEffect(() => {
    const savedOpenMenu = localStorage.getItem("openMenu");
    const savedScrollPosition = localStorage.getItem("sidebarScrollPosition");

    if (savedOpenMenu !== null) setOpenMenu(savedOpenMenu);
    if (savedScrollPosition !== null && sidebarRef.current) {
      sidebarRef.current.scrollTop = savedScrollPosition;
    }
  }, []);
  useEffect(() => {
    // Save scroll position on unmount or page navigation
    return () => saveScrollPosition();
  }, [location]);
  return (
    <Box
      ref={sidebarRef}
      sx={{
        width: "100%",
        height: "100%",
        padding: "0px 6px 20px 6px",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto", // Enable scrolling
        "&::-webkit-scrollbar": {
          display: "none", // Hide scrollbar for WebKit browsers
        },
        scrollbarWidth: "none", // Hide scrollbar for Firefox
        msOverflowStyle: "none", // Hide scrollbar for Internet Explorer and Edge
      }}
      onScroll={saveScrollPosition} //
    >
      <List>
        <ListItem
          onClick={() => {
            navigate("");
          }}
        >
          <Box className="sidebarprofieInfo">
            <img
              src={profilePicture || profileImg}
              alt="profileImg"
              className="sidebarprofieImg"
              style={{ objectFit: "contain", borderRadius: "50%" }}
            />
            <ListItemText
              sx={sidebarStyle.sidebarAdminName}
              primary={displayName || "admin"}
            />
          </Box>
        </ListItem>
        <Divider sx={{ marginBottom: 3 }} />
        <ListItem
          sx={
            isActive("dashboard")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/dashboard");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          sx={
            isActive("mumListing")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/mumListing");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <PregnantWomanIcon />
          </ListItemIcon>
          <ListItemText primary="User Management" />
        </ListItem>
        <ListItem
          sx={
            isActive("category")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/category");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <Category />
          </ListItemIcon>
          <ListItemText primary="Program Management" />
        </ListItem>

        <ListItem onClick={() => toggleMenu("stagewise")}>
          <ListItemIcon className="sidebarIcon">
            <EventNote sx={{ color: "rgb(225, 140, 168) !important" }} />
          </ListItemIcon>
          <ListItemText
            primary="Program Section Management"
            sx={sidebarStyle.sidebarItem}
          />
          {openMenu === "stagewise" ? (
            <ExpandLess sx={{ color: "rgb(225, 140, 168) !important" }} />
          ) : (
            <ExpandMore sx={{ color: "rgb(225, 140, 168) !important" }} />
          )}
        </ListItem>
        <Collapse in={openMenu === "stagewise"} timeout="auto" unmountOnExit>
          <List component="div" style={{ paddingLeft: "15px" }}>
            <ListItem
              sx={
                isActive("topiclisting")
                  ? sidebarStyle.activeSidbarList
                  : sidebarStyle.sidebarItem
              }
              onClick={() => {
                navigate("/topiclisting");
              }}
            >
              <ListItemIcon className="sidebarIcon">
                <EventNote />
              </ListItemIcon>
              <ListItemText primary="Section With Topic Listing" />
            </ListItem>
            <ListItem
              sx={
                isActive("program")
                  ? sidebarStyle.activeSidbarList
                  : sidebarStyle.sidebarItem
              }
              onClick={() => {
                navigate("/program");
              }}
            >
              <ListItemIcon className="sidebarIcon">
                <SportsHandball />
              </ListItemIcon>
              <ListItemText primary="Section  Without Topic Listing" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem onClick={() => toggleMenu("recipe")}>
          <ListItemIcon>
            <MenuBook sx={{ color: "rgb(225, 140, 168) !important" }} />
          </ListItemIcon>
          <ListItemText
            primary="Recipe Management"
            sx={sidebarStyle.sidebarItem}
          />
          {openMenu === "recipe" ? (
            <ExpandLess sx={{ color: "rgb(225, 140, 168) !important" }} />
          ) : (
            <ExpandMore sx={{ color: "rgb(225, 140, 168) !important" }} />
          )}
        </ListItem>
        <Collapse in={openMenu === "recipe"} timeout="auto" unmountOnExit>
          <List component="div" style={{ paddingLeft: "15px" }}>
            <ListItem
              sx={
                isActive("dietarylisting")
                  ? sidebarStyle.activeSidbarList
                  : sidebarStyle.sidebarItem
              }
              onClick={() => {
                navigate("/dietarylisting");
              }}
            >
              <ListItemIcon className="sidebarIcon">
                <LocalDining />
              </ListItemIcon>
              <ListItemText primary="Dietary Preferences Listing" />
            </ListItem>
            <ListItem
              sx={
                isActive("cuisionlisting")
                  ? sidebarStyle.activeSidbarList
                  : sidebarStyle.sidebarItem
              }
              onClick={() => {
                navigate("/cuisionlisting");
              }}
            >
              <ListItemIcon className="sidebarIcon">
                <Restaurant />
              </ListItemIcon>
              <ListItemText primary="Cuisine Listing" />
            </ListItem>
            <ListItem
              sx={
                isActive("diettype")
                  ? sidebarStyle.activeSidbarList
                  : sidebarStyle.sidebarItem
              }
              onClick={() => {
                navigate("/diettype");
              }}
            >
              <ListItemIcon className="sidebarIcon">
                <Fastfood />
              </ListItemIcon>
              <ListItemText primary="Diet Type Listing" />
            </ListItem>
            <ListItem
              sx={
                isActive("recipeType")
                  ? sidebarStyle.activeSidbarList
                  : sidebarStyle.sidebarItem
              }
              onClick={() => {
                navigate("/recipeType");
              }}
            >
              <ListItemIcon className="sidebarIcon">
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="RecipeType Listing" />
            </ListItem>
            <ListItem
              sx={
                isActive("recipelisting")
                  ? sidebarStyle.activeSidbarList
                  : sidebarStyle.sidebarItem
              }
              onClick={() => {
                navigate("/recipelisting");
              }}
            >
              <ListItemIcon className="sidebarIcon">
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Recipe Listing" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem onClick={() => toggleMenu("consult")}>
          <ListItemIcon>
            <MenuBook sx={{ color: "rgb(225, 140, 168) !important" }} />
          </ListItemIcon>
          <ListItemText
            primary="Consult Management"
            sx={sidebarStyle.sidebarItem}
          />
          {openMenu === "consult" ? (
            <ExpandLess sx={{ color: "rgb(225, 140, 168) !important" }} />
          ) : (
            <ExpandMore sx={{ color: "rgb(225, 140, 168) !important" }} />
          )}
        </ListItem>
        <Collapse in={openMenu === "consult"} timeout="auto" unmountOnExit>
          <List component="div" style={{ paddingLeft: "15px" }}>
            <ListItem
              sx={
                isActive("consults")
                  ? sidebarStyle.activeSidbarList
                  : sidebarStyle.sidebarItem
              }
              onClick={() => {
                navigate("/consults");
              }}
            >
              <ListItemIcon className="sidebarIcon">
                <MedicationIcon />
              </ListItemIcon>
              <ListItemText primary="Consult Listing" />
            </ListItem>

            <ListItem
              sx={
                isActive("serviceAndSpecialization")
                  ? sidebarStyle.activeSidbarList
                  : sidebarStyle.sidebarItem
              }
              onClick={() => {
                navigate("/serviceAndSpecialization");
              }}
            >
              <ListItemIcon className="sidebarIcon">
                <MedicalServicesIcon />
              </ListItemIcon>
              <ListItemText primary="Service/Specialization Listing" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          sx={
            isActive("workoutlisting")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/workoutlisting");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <SportsHandball />
          </ListItemIcon>
          <ListItemText primary="Workout Management" />
        </ListItem>

        <ListItem
          sx={
            isActive("forums")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/forums");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <ForumIcon />
          </ListItemIcon>
          <ListItemText primary="Forum Management" />
        </ListItem>
        <ListItem
          sx={
            isActive("ticketListing")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/ticketListing");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <ForumIcon />
          </ListItemIcon>
          <ListItemText primary="Ticket Management" />
        </ListItem>

        <ListItem
          sx={
            isActive("activatelisting")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/activatelisting");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <LocalDining />
          </ListItemIcon>
          <ListItemText primary="Activation Management" />
        </ListItem>

        <ListItem
          sx={
            isActive("couponListing")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/couponListing");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <QrCode2Icon />
          </ListItemIcon>
          <ListItemText primary="Coupon Management" />
        </ListItem>

        <ListItem
          sx={
            isActive("bookingListing")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/bookingListing");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText primary="Booking Management" />
        </ListItem>

        <ListItem
          sx={
            isActive("subscriptionListing")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/subscriptionListing");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <Subscriptions />
          </ListItemIcon>
          <ListItemText primary="Subscription Management" />
        </ListItem>

        <ListItem
          sx={
            isActive("optionlisting")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/optionlisting");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <KeyboardOptionKey />
          </ListItemIcon>
          <ListItemText primary="User Preferences Management" />
        </ListItem>
        <ListItem
          sx={
            isActive("pregnancyTrackerListing")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/pregnancyTrackerListing");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <TrackChanges />
          </ListItemIcon>
          <ListItemText primary="Pregnancy Tracker Management" />
        </ListItem>
        <ListItem
          sx={
            isActive("staticcontent")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/staticcontent");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <Book />
          </ListItemIcon>
          <ListItemText primary="Static Content Management" />
        </ListItem>
        <ListItem
          sx={
            isActive("myaccount")
              ? sidebarStyle.activeSidbarList
              : sidebarStyle.sidebarItem
          }
          onClick={() => {
            navigate("/myaccount");
            closeAllCollapses();
          }}
        >
          <ListItemIcon className="sidebarIcon">
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary="My Account" />
        </ListItem>

        <ListItem onClick={onLogoutClick} sx={sidebarStyle.sidebarItem}>
          <ListItemIcon className="sidebarIcon">
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );
};

export default Sidebar;
